import {Component, Inject, OnInit} from '@angular/core';
import {Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {AssetService} from "./services/asset.service";
import {CommonModule} from "@angular/common";
import {ClarityModule} from "@clr/angular";
import {CategoryService} from "./services/category.service";
import {Category} from "./interfaces/category";
import {filter, lastValueFrom, Subject, takeUntil} from "rxjs";
import {ClarityIcons, cogIcon} from "@cds/core/icon";
import {EventMessage, EventType, InteractionStatus} from "@azure/msal-browser";
import {MsalBroadcastService, MsalService} from "@azure/msal-angular";
import {AnalyticsService} from "./services/analytics.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, RouterLink, RouterLinkActive, ClarityModule, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  isLoading = false;
  isOnline = true;
  categories: Category[] = [];
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(private assetService: AssetService, private categoryService: CategoryService,
              private authService: MsalService,
              private msalBroadcastService: MsalBroadcastService,
              private analyticsService: AnalyticsService,
              private router: Router) {
    ClarityIcons.addIcons(cogIcon);

  }

  async ngOnInit() {

    this.analyticsService.trackEvent('', '', '');
    window.addEventListener('online', () => this.isOnline = true)
    window.addEventListener('offline', () => this.isOnline = false)

    this.assetService.isLoading$.subscribe(next => this.isLoading = next);
    setTimeout(async () => {
      const response = await lastValueFrom(this.categoryService.getCategories());
      for (let category of response) {
        if (category.parent == null || category.parent === 0) {
          this.categories.push(category);
        } else {
          let currentIndex = this.categories.findIndex(cat => cat.id === category.parent);
          if (currentIndex == -1) {
            // Child with no active parent
            continue;
          }
          if (this.categories[currentIndex].children == undefined) {
            this.categories[currentIndex].children = [];
          }
          this.categories[currentIndex].children!.push(category);
        }
      }
    }, 100);

    this.authService.handleRedirectObservable().subscribe(async next => {
      if(next) {
        // Es wurde eine Anmeldung durchgeführt, der User soll nun auf die Startseite geleitet werden. Damit alle Einstellungen übernohmen werden
        // soll anschließend die Seite einmal neu geladen werden.
        await this.router.navigateByUrl('/app/home');
        window.location.reload();
      }
    }
  );

    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (!this.loginDisplay) {
      this.router.navigateByUrl('/login');
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

}
