import {Component, Input} from '@angular/core';
import {Asset} from "../../interfaces/asset";
import {NgClass, NgIf, NgStyle} from "@angular/common";

@Component({
  selector: 'app-asset',
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    NgClass
  ],
  templateUrl: './asset.component.html',
  styleUrl: './asset.component.css'
})
export class AssetComponent {
  @Input('asset') asset: Asset | undefined;
  @Input('showTitle') showTitle: boolean = false;
  @Input('editMode') editMode: boolean = false;
}
