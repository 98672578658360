<div [ngClass]="{'fetching': isFetchingSingle}">
  <div class="clr-row">
    <div class="clr-col">
      <h1>Verfügbare Elemente</h1>
      <clr-toggle-container style="display: none">
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle [(ngModel)]="editMode" />
          <label>Bearbeitungsmodus</label>
        </clr-toggle-wrapper>
      </clr-toggle-container>
    </div>
    <div class="clr-col-2">
      <clr-input-container style="align-items: flex-end">
        <input [disabled]="isLoading" clrInput placeholder="Suchbegriff..." (keyup)="filterAssets($event)">
      </clr-input-container>
    </div>
  </div>
  <div class="clr-row">
    <div class="clr-col-sm-4 clr-col-md-3" *ngFor=" let asset of filteredAssets">
      <app-asset [asset]="asset" (click)="assetClicked(asset)" [showTitle]="true" [editMode]="editMode"></app-asset>
    </div>
    <div class="clr-col" *ngIf="!isLoading && assets.length == 0">
      <clr-alert [clrAlertClosable]="false">
        <clr-alert-item>
          <span class="alert-text">In dieser Kategorie sind leider keine Inhalte vorhanden. Wenn du zu neuen Inhalten beitragen möchtest, kontaktiere gerne direkt das Marketing.</span>
        </clr-alert-item>
      </clr-alert>
    </div>
  </div>
</div>

<clr-modal [(clrModalOpen)]="openEditModal">
  <h3 class="modal-title">Asset bearbeiten</h3>
  <div class="modal-body">
    <p>Ausgewähltes Asset: {{selectedAsset?.title}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="openEditModal = false; selectedAsset = null">Abbrechen</button>
    <button type="button" class="btn btn-primary" (click)="openEditModal = false; selectedAsset = null">Speichern</button>
  </div>
</clr-modal>
