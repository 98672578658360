import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Asset} from "../../interfaces/asset";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {AssetComponent} from "../asset/asset.component";
import {AssetService} from "../../services/asset.service";
import {ConnectorService} from "../../services/connector.service";
import {ClrAlertModule, ClrCheckboxModule, ClrInputModule, ClrModalModule} from "@clr/angular";
import {filter, lastValueFrom} from "rxjs";
import {FormsModule} from "@angular/forms";
import {AnalyticsService} from "../../services/analytics.service";

@Component({
  selector: 'app-asset-wrapper',
  standalone: true,
  imports: [
    NgForOf,
    AssetComponent,
    ClrAlertModule,
    NgIf,
    NgClass,
    ClrInputModule,
    ClrCheckboxModule,
    FormsModule,
    ClrModalModule
  ],
  templateUrl: './asset-wrapper.component.html',
  styleUrl: './asset-wrapper.component.css'
})
export class AssetWrapperComponent implements OnInit {

  selectedCategory: number = 0;
  assets: Asset[] = [];
  filteredAssets: Asset[] = [];
  isLoading = false;
  isFetchingSingle = false;
  fetchedSingleID = -1;
  currentCycle = 0;
  editMode = false;
  openEditModal = false;
  selectedAsset: Asset | null = null;

  constructor(private activatedRoute: ActivatedRoute,
              private connectorService: ConnectorService,
              private analyticsService: AnalyticsService,
              private assetService: AssetService) {
  }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async params => {
      this.currentCycle++;
      const cycle = this.currentCycle;
      this.selectedCategory = params['categoryID'];
      this.analyticsService.trackEvent('Category selected', `Category ${this.selectedCategory}`, 'SELECT_CATEGORY')
      this.assets = [];
      this.filteredAssets = []
      this.isLoading = true;
      const windowSize = 5;
      let offset = 0;
      let resp: Asset[] = []
      do {
        resp = await lastValueFrom(this.assetService.get(this.selectedCategory, offset, windowSize));
        // Keine Einträge zur Liste hinzufügen, wenn in der Zwischenzeit ein neuer Zyklus angefangen hat.
        if(this.currentCycle != cycle) {
          break;
        }
        this.assets.push(...resp);
        this.filteredAssets.push(...resp);
        offset += windowSize;
      } while(resp.length == windowSize)
      if (this.assets.length !== 0) {
        // this.assetService.addToDB(this.assets);
      }
      this.isLoading = false;
    });
  }

  async assetClicked(asset: Asset) {
    if (this.editMode) {
      // TODO: Open edit panel
      this.selectedAsset = asset;
      this.openEditModal = true;
      return;
    }
    if (this.isFetchingSingle) {
      return;
    }
    this.isFetchingSingle = true;
    this.fetchedSingleID = asset.id;
    const single = await this.assetService.getSingle(asset.id);
    this.isFetchingSingle = false;
    this.fetchedSingleID = -1;
    if (single.length != 1) {
      // TODO: Notification an den User schicken
      return;
    }
    this.connectorService.addAsset(single[0]);
  }

  async filterAssets(event: any) {
    this.filteredAssets = this.assets.filter(item => {
      return item.title.toLowerCase().replaceAll(' ', '').includes(event.target.value.toLowerCase().replaceAll(' ', ''));
    })
  }

  protected readonly filter = filter;
}
