<div class="main-container">
  <header class="header-6">
    <div class="branding">
      <span>Powerslide</span>&nbsp;<span style="font-weight: 200">2.0</span>
    </div>
    <div class="settings" *ngIf="loginDisplay">
      <clr-dropdown>
        <a class="nav-icon" clrDropdownTrigger>
          <cds-icon shape="cog"></cds-icon>
        </a>
        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
          <a href="#" routerLink="/app/about" clrDropdownItem>Über die App</a>
          <a href="#" routerLink="/login" clrDropdownItem (click)="logout(false)">Abmelden</a>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </header>
  <div class="content-container">
    <clr-vertical-nav *ngIf="loginDisplay">
      <div *ngFor="let category of categories">
        <a *ngIf="!category.children" clrVerticalNavLink [routerLink]="'app/assets/' + category.id"
           routerLinkActive="active">
          <span class="material-symbols-outlined" clrVerticalNavIcon>{{ category.icon }}</span>
          {{ category.name }}</a>
        <clr-vertical-nav-group *ngIf="category.children" routerLinkActive="active">
          <span class="material-symbols-outlined" clrVerticalNavIcon>{{ category.icon }}</span>
          {{ category.name }}
          <clr-vertical-nav-group-children>
            <a *ngFor="let child of category.children" clrVerticalNavLink [routerLink]="'app/assets/' + child.id"
               routerLinkActive="active">{{ child.name }}</a>
          </clr-vertical-nav-group-children>
        </clr-vertical-nav-group>
      </div>
    </clr-vertical-nav>
    <div class="content-area">
      <div class="outlet-container">
        <router-outlet></router-outlet>
      </div>
      <div class="footer-toolbar">

        <div class="state-element" *ngIf="isOnline">
          <p><span class="led-badge green"></span>Online</p>
        </div>
        <div class="state-element" *ngIf="!isOnline">
          <p><span class="led-badge red"></span>Offline</p>
        </div>
        <div class="state-element" style="align-self: center;" *ngIf="isLoading">
          <span class="spinner spinner-sm">Loading...</span>
          <p style="margin-left: 1rem;">Daten werden abgerufen...</p>
        </div>
      </div>
    </div>
  </div>
</div>
