<div class="card" [ngClass]="{'scale': !editMode}">
  <div class="card-img">
    <img src="data:image/png;base64,{{asset?.thumbnail}}" style="max-width: 100%">
  </div>
  <div class="card-block" *ngIf="showTitle">

    <p class="card-text">{{asset?.title}}</p>
  </div>
</div>

