<div class="clr-row!">
  <div class="clr-col-12" style="margin-bottom: 32px;">
    <h1>Hallo!</h1>
    <p>Willkommen beim powerslide Tool der cronos.</p>
  </div>
  <div class="clr-col-md-6 clr-col-12">
    <div class="card">
      <a
        href="https://cronosnet.sharepoint.com/teams/C_181/_layouts/15/embed.aspx?UniqueId=c5e22210-2329-4f77-905f-d88ad69dda63"
        target="_blank">
        <div class="card-img">
          <img src="assets/img/screen_video.jpg" style="max-width: 100%; height: auto; "
               alt="Screenshot von einem Erklärvideo. Rechts steht Robert. Links ist sein Bildschirminhalt zu sehen.">
        </div>
      </a>
      <div class="card-block">
        <p class="card-text">
          Robert gibt euch eine kurze Einleitung in die Benutzung von Powerslide 2.0 .
        </p>
      </div>
    </div>

  </div>
</div>


