import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpStatusCode,
} from "@angular/common/http";
import { Observable, catchError, from, map, mergeMap } from "rxjs";
import { MsalService } from "@azure/msal-angular";
import {environment} from "../../enviroments/environment";

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  constructor(
    private msalService: MsalService,
  ) {}

  token: any;

  /**
   * Intercepts HTTP requests to the own backend and adds an access token to
   * the header before making the request.
   * @param request the request that has to be intercepted
   * @param next handler for next request step
   * @returns an observable with the http response from the server
   */
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // Update service

    if (!this.msalService.instance.getActiveAccount())
      throw Error("No logged in user!");

    const httpHeader = {
      Authorization: "",
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    // Get access token from azure with own api scope
    return from(
      this.msalService.instance.acquireTokenSilent({
        scopes: environment.apiConfig.scopes,
      })
    ).pipe(
      mergeMap((data) => {
        const accessToken = data.idToken;
        // add access token
        httpHeader.Authorization = `Bearer ${accessToken}`;
        const req = request.clone({ setHeaders: httpHeader });
        return next.handle(req);
      }),
      catchError((err) => {
        throw err;
      })
    );
  }

}
