import {Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Category} from "../interfaces/category";
import {environment} from "../../enviroments/environment";
import {Asset} from "../interfaces/asset";

@Injectable({
  providedIn: 'root'
})
export class AssetService {

  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  db: any;

  constructor(private http: HttpClient) {
    const openRequest = window.indexedDB.open('assets_db', 1);
    openRequest.addEventListener('error', () => {
      console.error('database failed to open');
    });

    openRequest.addEventListener("success", () => {
      console.log("Database opened successfully");

      // Store the opened database object in the db variable. This is used a lot below
      this.db = openRequest.result;
      // Run the displayData() function to display the notes already in the IDB
    });

    openRequest.addEventListener("upgradeneeded", (e) => {
      // Grab a reference to the opened database
      // @ts-ignore
      let db = e.target.result;

      // Create an objectStore in our database to store notes and an auto-incrementing key
      // An objectStore is similar to a 'table' in a relational database
      // @ts-ignore
      const objectStore = db.createObjectStore("assets", {
        keyPath: "id",
        autoIncrement: true,
      });

      // Define what data items the objectStore will contain
      objectStore.createIndex("title", "title", {unique: false});
      objectStore.createIndex("description", "body", {unique: false});
      objectStore.createIndex("type", 'type', {unique: false});
      objectStore.createIndex("encoded_data", "encoded_data", {unique: false});
      objectStore.createIndex('thumbnail', 'thumbnail', {unique: false});

      console.log("Database setup complete");
    });
  }

  get(category: number, offset: number, limit: number): Observable<Asset[]> {
    return this.http.get<Asset[]>(`${environment.appConfig.apiEndpoint}/assets?category=${category}&offset=${offset}&limit=${limit}`);
  }

  getSingle(id: number): Promise<Asset[]> {
    return lastValueFrom(this.http.get<Asset[]>(`${environment.appConfig.apiEndpoint}/singleAsset?id=${id}`));
  }

  showLoader() {
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(true);
    }
    this.apiCount++;
  }

  hideLoader() {
    this.apiCount--;
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(false);
    }
  }

  addToDB(assets: Asset[]) {
    const transaction = this.db.transaction(['assets'], 'readwrite');
    const objectStore = transaction.objectStore('assets');
    for (const asset of assets) {
      objectStore.add(asset);
    }

    // Report on the success of the transaction completing, when everything is done
    transaction.addEventListener("complete", () => {
      console.log("Transaction completed: database modification finished.");
    });

    transaction.addEventListener("error", (e: any) => {
        if (e.target.error.name != 'ConstraintError') {
          console.log(e);
          console.log("Transaction not opened due to error");
        }
      }
    );

    transaction.commit();

  }
}
