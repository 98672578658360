import {Routes} from '@angular/router';
import {HomeComponent} from "./components/home/home.component";
import {LoginComponent} from "./components/login/login.component";
import {AssetWrapperComponent} from "./components/asset-wrapper/asset-wrapper.component";
import {AboutComponent} from "./components/about/about.component";
import {UploadComponent} from "./components/upload/upload.component";

export const routes: Routes = [
  {
    path: 'app',
    children: [
      {
        path: 'home',
        component: HomeComponent
      },
      {
        path: 'about',
        component: AboutComponent
      },
      {
        path: 'assets/:categoryID',
        component: AssetWrapperComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '**',
    component: HomeComponent
  }
];
